import React from 'react'
import PropTypes from 'prop-types'
import Base from '../layouts/base'
import styled from 'styled-components'
import Helmet from 'react-helmet'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  max-width: 500px;
  margin: 0 auto;
  color: #fff;
  > :first-child {
    font-family: 'Dancing Script', cursive;
  }
  > :nth-child(2) {
    font-style: italic;
  }
`

const PrivacyPage = (props) => {
  return <Base>
    <Helmet title='Politique de confidentialité' />
    <Content>
      <h1>Le site des Amoureux ❤️</h1>
      <div>
        <p>Ce site est destiné à un usage privé entre Axelle et moi-même.</p>
      </div>
      <div>
        <h1>Politique de confidentialité</h1>
        <h3>Connexion de votre compte Google</h3>
        <p>En vous connectant en utilisant Google, vous autorisez le site des amoureux à utiliser votre adresse email, nom, photo de profil et autres informations de base associées à ce dernier.</p>
      </div>
    </Content>
  </Base>
}

PrivacyPage.propTypes = {

}

PrivacyPage.defaultProps = {

}

export default PrivacyPage
